export default class ModLogin {
  constructor(el) {
    this.$this = $(el);
    this.init();
  }

  init() {
    if (this.$this.length === 0) return;
    this.$form = this.$this.find('form');
    this.$textFields = this.$form.find('input[type="text"]');
    this.$passwordFields = this.$form.find('input[type="password"]');
    this.$emailFields = this.$form.find('input[type="email"]');
    this.$submitButton = this.$form.find('[type="submit"]');
    this.$this.find('.um-field').each((index, element) => {
      const labelText = $(element).find('label').text();
      const $input = $(element).find('input');
      if (labelText !== '') {
        $input.attr('placeholder', labelText.indexOf('*') > -1 ? labelText : `${labelText}*`);
      }
    });
    this.$submitButton.on('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.validate();
    });
    this.updateUrl();
  }

  updateUrl() {
    const url = new URL(window.location.href);
    const paramsToRemove = ['message', 'um_role', 'um_form_id'];

    const allParamsExist = paramsToRemove.every(param => url.searchParams.has(param));

    if (allParamsExist && $('.um-postmessage').length && $('.um-register').length) {
      paramsToRemove.forEach(param => url.searchParams.delete(param));
      url.hash = 'thank-you';

      // Update the browser's URL
      window.history.replaceState({}, document.title, url.toString());
    }
  }

  validate() {
    let isValid = true;

    // Validate text fields
    this.$textFields.each((index, element) => {
      const $element = $(element);
      if ($element.val().trim() === '') {
        isValid = false;
        $element.addClass('error');
      } else {
        $element.removeClass('error');
      }
    });

    // Validate password fields
    this.$passwordFields.each((index, element) => {
      const $element = $(element);
      if ($element.val().trim() === '') {
        isValid = false;
        $element.addClass('error');
      } else {
        $element.removeClass('error');
      }
    });

    // Validate email fields
    this.$emailFields.each((index, element) => {
      const $element = $(element);
      const email = $element.val().trim();
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (email === '' || !emailPattern.test(email)) {
        isValid = false;
        $element.addClass('error');
      } else {
        $element.removeClass('error');
      }
    });

    if (isValid) {
      this.$form.submit();
    } else {
      this.$this.find('.error').first().focus();
    }
  }
}

new ModLogin('.login-page');