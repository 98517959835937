export default class Footer {
  constructor() {
    this.$this = $('footer')
    this.$controlMenu = this.$this.find('.control-menu-footer')
    this.reverseClass = 'reverse-icon'
    this.clsHidden = 'hidden'
    this.clsActive = 'grid'
    this.clsIconControlMenu = '.icon-control-menu'
  }

  init() {
    if (this.$this.length) {
      this.activeMenuInMobile()
    }
  }

  activeMenuInMobile() {
    this.$controlMenu.on('click', (e) => {
      const ele = e.currentTarget
      if (window.innerWidth < 768) {
        e.preventDefault()
        const $currentMenu = $(ele).next('.sub-menu-footer')
        if (!$currentMenu.hasClass(this.clsActive)) {
          $('.sub-menu-footer').addClass(this.clsHidden)
          $(this.clsIconControlMenu).removeClass(this.reverseClass)
          $currentMenu.removeClass(this.clsHidden).addClass(this.clsActive)
          $(ele).find(this.clsIconControlMenu).addClass(this.reverseClass)
        } else {
          $currentMenu.removeClass(this.clsActive).addClass(this.clsHidden)
          $(ele).find(this.clsIconControlMenu).removeClass(this.reverseClass)
        }
      }
    })
  }

}
new Footer().init()
