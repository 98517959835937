export default class UserAgent {
  constructor() {
    this.$html = $('html')
  }

  init() {
    this.setResponsiveTable()
    this.removeNoscript()
    this.setPositionDots()
    $('.dot-block').addClass('set-done')
    $(window).on('resize', () => {
      this.setPositionDots()
    })
    this.setAttrLanguage()
  }

  removeNoscript() {
    $('#notify').remove()
  }

  setResponsiveTable() {
    const $tables = $('.accordion-content table, .mod-content-post table');
    if ($tables.length === 0) return;
    $tables.each((_i, e) => {
      $(e).wrap('<div class="table-responsive"></div>');
    });
  }

  getTranslateY(element) {
    const style = window.getComputedStyle(element);
    const transform = style.transform || style.webkitTransform || style.mozTransform;
    const matrix = transform.match(/^matrix3d\((.+)\)$/);
    if (matrix) return parseFloat(matrix[1].split(', ')[13]);
    const matrix2d = transform.match(/^matrix\((.+)\)$/);
    return matrix2d ? parseFloat(matrix2d[1].split(', ')[5]) : 0;
  }

  setPositionDots() {
    $('.position-dot').each((_i, e) => {
      const hasMarkBorder = ':has(>.mark-border .dot-block)'
      const $module = $(e).closest(`.gravity-block${hasMarkBorder}, .block-item${hasMarkBorder}`)
      const $dot = $module.find('>.mark-border .dot-block')
      if ($dot.length === 0 || !$(e).is(':visible')) return
      const $prAnis = $(e).parents('.anima-bottom')
      let translateY = 0
      if ($prAnis.length) {
        $prAnis.each((_ii, ee) => {
          translateY += this.getTranslateY(ee)
        })
      }
      const topPosition = $(e).offset().top - $module.offset().top + (parseInt($(e).css('line-height'), 10) / 2) - translateY
      $dot.css('top', topPosition - $dot.height() / 2)
    })
  }

  setAttrLanguage() {
    document.querySelectorAll('li.wg-li:not(.wgcurrent)').forEach((li) => {
      const dataCodeLanguage = li.getAttribute('data-code-language')
      const aTag = li.querySelector('a')
      if (aTag) {
        aTag.setAttribute('data-code-language', dataCodeLanguage)
      }
    });
  }

}

const userAgent = new UserAgent()
userAgent.init()
window.userAgent = userAgent