import SimpleScroll from '../SimpleScroll'

export default class Menu {
  constructor() {
    this.this = '#main-menu'
    this.$this = $(this.this)
    this.$mainMenu = $('#main-menu').find('.main-menu-ul')
    this.elementItem = '.hamburger-menu, html, #main-menu, #header'
    this.$header = $('#header, #main-menu-mobile')
    this.$hamburgerMenu = $('.hamburger-menu')
    this.li = this.$this.find('.main-menu-ul >li>a')
    this.liLevel1 = this.$this.find('.main-menu-ul >li:not(.menu-item-custom)')
    this.liLevel2 = this.$this.find('.main-menu-ul .main-menu-dropdown li.menu-item-custom')
    this.liLevel3 = this.$this.find('.main-menu-ul .main-menu-dropdown-1 li>a')
    this.isOpenMenu = 'is-open-menu'
    this.isOpenMenuChild = 'open-sub-menu'
    this.isOpenChild = 'is-open-child'
    this.$linksInMenu = $('.header a:not(.skip-link), .header button')
    this.$firstLinkInMenu = this.$linksInMenu.first()
    this.$lastLinkInMenu = this.$linksInMenu.last()
    this.$skipLink = $('.header a.skip-link')
    this.ariaExpanded = 'aria-expanded'
    this.classHover = 'hovering'
    this.notDesktop = 1200
    this.openMainMenuCls = 'open-main-menu'
    this.$backButton = this.$this.find('.btn-back')
    this.$searchBtn = $('.search-mobile')
    this.subMenuCls = '.sub-menu-wrapper'
  }

  init() {
    if (this.$this.length) {
      this.openMainMenu()
      this.clickLiOpenDropdownMenuLevel1and2(this.liLevel1)
      this.clickLiOpenDropdownMenuLevel1and2(this.liLevel2)
      this.clickArrowOpenDropdownMenuLevel2()
      this.clickArrowOpenDropdownMenuLevel1()
      this.clickOutSite()
      this.toggleTabIndexSkipLink()
      this.hoverOpenMenu()
      this.backEvent()
      window.addEventListener('resize', this.setHeightMainMenuUl.bind(this))
    }
  }

  lazyLoadImageMenu() {
    this.$this.find(`.hovering, .${this.openMainMenuCls}`).find('.lazy-header').each((_i, el) => {
      const $el = $(el)
      const src = $el.attr('data-src')
      if (src) {
        $el.attr('src', src).removeAttr('data-src').removeClass('lazy-header').addClass('b-loaded')
      }
    })
  }

  setHeightMainMenuUl() {
    const $menuInner = this.$this.find('.main-menu-inner')

    if (window.innerWidth >= 1200) {
      $menuInner.css('height', '')
      this.$this.find(this.subMenuCls).css('height', '')
      return
    }
    const $activeMenu = this.$this.find(`.${this.openMainMenuCls}`).last()
    const height = $activeMenu.length ? $activeMenu.find(this.subMenuCls).eq(0).find('>ul').height() : this.$mainMenu.height()
    $activeMenu.parents(this.subMenuCls).css('height', height)
    $menuInner.css('height', height)
    this.lazyLoadImageMenu()
  }

  isNotDesktop() {
    return window.innerWidth < this.notDesktop
  }

  backEvent() {
    this.$backButton.on('click', (e) => {
      e.preventDefault()
      e.stopPropagation()
      const ele = e.currentTarget
      const eleParent = $(ele).parents(`.${this.openMainMenuCls}`).first()
      eleParent.removeClass(this.openMainMenuCls)
      this.setHeightMainMenuUl()
    })
  }

  /* micro function */
  microOpenCloseLevel1(currentElement, openClass, isLiLv1 = false) {
    const ele = currentElement.currentTarget
    const eleParent = $(ele).parents('li.menu-item')
    if (this.isNotDesktop()) {
      if (!eleParent.hasClass(this.openMainMenuCls)) {
        this.liLevel1.removeClass(this.openMainMenuCls)
        this.liLevel1.find('> a').each((_i, el) => {
          if ($(el).attr(this.ariaExpanded)) {
            $(el).attr(this.ariaExpanded, false)
          }
        })
        eleParent.addClass(this.openMainMenuCls)
        eleParent.attr(this.ariaExpanded, true)
        this.setHeightMainMenuUl()
        if (isLiLv1) {
          return false
        }
      } else {
        eleParent.removeClass(this.openMainMenuCls)
        eleParent.attr(this.ariaExpanded, false)
        this.setHeightMainMenuUl()
      }
    }
    return true
  }
  /* end micro */

  openMainMenu() {
    this.$header.on('click', '.hamburger-menu', (e) => {
      const ele = e.currentTarget

      if ($(ele).hasClass(this.isOpenMenu)) {
        $(this.elementItem).removeClass(this.isOpenMenu)
        this.$hamburgerMenu.find('.sr-only').text('Open menu')
        this.$hamburgerMenu.attr(this.ariaExpanded, false)
        this.$skipLink.removeAttr('tabindex')
        this.$searchBtn.removeClass('hidden')
      } else {
        $(this.elementItem).addClass(this.isOpenMenu)
        this.$hamburgerMenu.find('.sr-only').text('Close menu')
        this.$hamburgerMenu.attr(this.ariaExpanded, true)
        this.$searchBtn.addClass('hidden')
        this.bindEventFocusOnMenu()
        this.setHeightMainMenuUl()
      }
    })
  }

  toggleTabIndexSkipLink() {
    $(window).on('resize', () => {
      if ($(window).outerWidth() < 1200) {
        this.$skipLink.removeAttr('tabindex')
      }
    })
  }

  bindEventFocusOnMenu() {
    this.$skipLink.attr('tabindex', -1)
    this.$firstLinkInMenu.get(0).addEventListener('keydown', this.shiftTabFocusToLast.bind(this))
    this.$lastLinkInMenu.get(0).addEventListener('keydown', this.tabFocusToFirst.bind(this))
  }

  tabFocusToFirst(e) {
    if (e.which === 9 && $(window).outerWidth() < 1200 && !e.shiftKey) {
      e.preventDefault()
      this.$firstLinkInMenu.focus()
    }
  }

  shiftTabFocusToLast(e) {
    if (e.which === 9 && $(window).outerWidth() < 1200 && e.shiftKey) {
      e.preventDefault()
      this.$lastLinkInMenu.focus()
    }
  }

  clickArrowOpenDropdownMenuLevel1() {
    this.liLevel1.on('click', '.icon-main-menu', (e) => {
      if (this.isNotDesktop()) {
        const isLiLv1 = true
        this.microOpenCloseLevel1(e, this.isOpenChild, isLiLv1)
        this.setHeightMainMenuUl()
      }
    })
  }

  clickArrowOpenDropdownMenuLevel2() {
    this.liLevel2.on('click', '.icon-sub-menu', (e) => {
      const ele = e.currentTarget
      const eleParent = $(ele).parent()
      if (window.innerWidth < 1200) {
        if (!eleParent.hasClass(this.openMainMenuCls)) {
          eleParent.addClass(this.openMainMenuCls)
        } else {
          eleParent.removeClass(this.openMainMenuCls)
        }
        this.setHeightMainMenuUl()
      }
    })
  }

  clickOutSite() {
    $(document).on('click', (event) => {
      if (!$(event.target).closest('#header.is-open-menu').length) {
        $(this.elementItem).removeClass(this.isOpenMenu)
      }
    })
  }

  clickLiOpenDropdownMenuLevel1and2(li) {
    li.on('click', (e) => {
      const ele = e.currentTarget
      if (this.isNotDesktop() ) {
        if (!$(ele).hasClass(this.openMainMenuCls)) {
          $(ele).addClass(this.openMainMenuCls)
          this.setHeightMainMenuUl()
        } else {
          this.setHeightMainMenuUl()
        }
      }
    })
  }

  closeAllSubmenu() {
    const $liHover = this.$this.find('.main-menu-ul>li.hovering')
    $liHover.removeClass('hovering')
    if ($liHover.hasClass('has-sub')) {
      $liHover.find('> a').attr(this.ariaExpanded, false)
    }
  }

  hoverOpenMenu() {
    const classHoverHeader = 'pin-color'
    let timeout
    let timeHeader
    this.liLevel1.on('mouseover', (e) => {
      const liHover = this.$this.find(`.main-menu-ul>li.${this.classHover}`)
      this.$header.addClass(classHoverHeader)
      if (typeof window.lazyLoadBg !== 'undefined') {
        window.lazyLoadBg.init()
      }
      if (liHover.length < 2) {
        const ele = e.currentTarget
        clearTimeout(timeout)
        clearTimeout(timeHeader)
        timeout = setTimeout(() => {
          this.closeAllSubmenu()
          $(ele).addClass(this.classHover)
          this.lazyLoadImageMenu()
          if ($(ele).hasClass('has-sub')) {
            $(ele).find('> a').attr(this.ariaExpanded, true)
          }
        }, 100)
      }
      this.lazyLoadImageMenu()
    })

    this.$mainMenu.on('mouseleave', () => {
      if ($(window).outerWidth() > 991) {
        clearTimeout(timeout)
        clearTimeout(timeHeader)
        timeout = setTimeout(() => {
          this.closeAllSubmenu()
        }, 50)
        timeHeader = setTimeout(() => {
          this.$header.removeClass(classHoverHeader)
          this.closeAllSubmenu()
        }, 200)
      }
    })

    $(window).on('keydown', (e) => {
      if (e.keyCode === 27 && $(window).outerWidth() > 991) {
        clearTimeout(timeout)
        clearTimeout(timeHeader)
        timeout = setTimeout(() => {
          this.closeAllSubmenu()
        }, 50)
        timeHeader = setTimeout(() => {
          this.$header.removeClass(classHoverHeader)
          this.closeAllSubmenu()
        }, 200)
      }
    })
  }
}

new Menu().init()