// import React from 'react';
// import { createRoot } from 'react-dom/client';
// import { Provider } from 'react-redux';

const modules = [
  // React Modules
  // {
  //   name: 'ShopAccessories',
  //   loader: () => import('./modules/react/shop-accessories/ShopAccessoriesRoot.js'),
  //   async render(Root, el) {
  //     const store = await import('./modules/react/shop-accessories/store/index.js');
  //     createRoot(el[0]).render(<Provider store={store.store}><Root rootElement={el[0]} /></Provider>);
  //   },
  // },
  // JS Modules
  // {
  //   name: 'Typography',
  //   loader: () => import('./components/Typography'),
  // },
  {
    name: 'FormAnimation',
    loader: () => import('./components/FormAnimation'),
  },
  {
    name: 'TabList',
    loader: () => import('./modules/TabList'),
  },
  {
    name: 'ModAccordion',
    loader: () => import('./modules/ModAccordion'),
  },
  {
    name: 'ModLatestPosts',
    loader: () => import('./modules/ModLatestPosts'),
  },
  {
    name: 'ModFeatureResource',
    loader: () => import('./modules/ModFeatureResource'),
  },
  {
    name: 'ModLastedInsights',
    loader: () => import('./modules/ModLastedInsights'),
  },
  {
    name: 'ModFilterPosts',
    loader: () => import('./modules/ModFilterPosts'),
  },
  {
    name: 'ModCategoryList',
    loader: () => import('./modules/ModCategoryList'),
  },
  {
    name: 'ModGalleryProducts',
    loader: () => import('./modules/ModGalleryProducts'),
  },
  {
    name: 'ModFilterProducts',
    loader: () => import('./modules/ModFilterProducts'),
  },
  {
    name: 'ModLocationList',
    loader: () => import('./modules/ModLocationList'),
  },
  {
    name: 'ModProductsSearch',
    loader: () => import('./modules/ModProductsSearch'),
  },
  {
    name: 'ModBrandLogo',
    loader: () => import('./modules/ModBrandLogo'),
  },
  {
    name: 'ModLogin',
    loader: () => import('./modules/ModLogin'),
  },
  {
    name: 'ModSearchGas',
    loader: () => import('./modules/ModSearchGas'),
  },
];

export default [...modules];
