export default class AnimationScrollPage {
  constructor() {
    this.$el = $('.animation')
    this.winH = window.innerHeight
    this.winW = window.innerWidth
    this.offset = window.innerHeight
    this.WindowScrollTop = null
    this.topCurrent = null
    this.addClassAni = 'set-animation'
  }

  init() {
    this.animationEle()
    $(window).on('scroll resize', () => {
      this.animationEle()
    })
  }

  formatNumber(text, min, max) {
    return text.toLocaleString('en-US', {
      minimumFractionDigits: min,
      maximumFractionDigits: max,
    })
  }

  animationNumber($el) {
    const numberCount = parseFloat($el.attr('data-numbercounter'))
    const $parent = $el.parents('.mod-fact-star')
    const durationAnima = $parent.attr('data-duration') ? parseFloat($parent.attr('data-duration')) : 4000
    $el.addClass('counted')
    $el.prop('Counter', 0).animate({
      Counter: numberCount,
    }, {
      duration: durationAnima,
      easing: 'swing',
      step: (now) => {
        $el.text(this.formatNumber(Math.floor(now), 0, 2))
      },
      complete: () => {
        $el.text(this.formatNumber(numberCount, 0, 2))
      },
    })
  }

  animationEle() {
    this.offset = this.winH - (this.winH * 0.1)
    if ($('.has-animation').length) {
      this.WindowScrollTop = $(window).scrollTop()
      this.$el.each((_index, ele) => {
        const $elm = $(ele)
        if ($elm.hasClass(this.addClassAni)) {
          return true
        }
        this.topCurrent = $elm.offset().top + parseInt($elm.css('padding-top'), 10)
        if (this.WindowScrollTop > this.topCurrent - this.offset) {
          $elm.addClass(this.addClassAni)
          setTimeout(() => {
            if (typeof window.lazyLoadImage !== 'undefined' && $elm.find('.lazy').length) {
              window.lazyLoadImage.lazyLoadImage()
            }
          }, 600)
          if ($elm.hasClass('number-counter') && !$elm.hasClass('counted')) {
            this.animationNumber($elm)
          }
        }
        return true
      })
    }
  }
}

new AnimationScrollPage().init()
