export default class BackgroundVideo {
  constructor(el) {
    this.$this = $(el)
    setTimeout(() => {
      this.init()
    }, 1000)
  }

  init() {
    this.loadVideo();
    $(window).on('resize', () => {
      this.loadVideo()
    })
  }

  loadVideo() {
    const typeVideo = this.$this.data('type')
    if (typeVideo !== 'host_self') {
      return;
    }
    const videoSrc = this.$this.data('src')
    if (window.innerWidth >= 992) {
      if (videoSrc && this.$this.length) {
        if (!this.$this.find('video').length) {
          const video = document.createElement('video')
          video.autoplay = true
          video.loop = true
          video.muted = true
          video.playsInline = true
          video.className = `background-video-inner transform-center-middle`
          const source = document.createElement('source')
          source.src = videoSrc
          source.type = 'video/mp4'
          video.appendChild(source)
          this.$this.append(video)
          this.$this.removeAttr('data-src')
        }
      }
    } else {
      const video = this.$this.find('video')
      if (video.length) {
        video.remove()
      }
      if (!this.$this.attr('data-src')) {
        this.$this.attr('data-src', videoSrc)
      }
    }
  }
}