export default class Header {
  constructor() {
    this.header = '#header'
    this.$header = $(this.header)
    this.numberScroll = 0
    this.scrollTop = 0
    this.class = 'pin-header'
    this.activeCls = 'active'
    this.$openSearch = this.$header.find('.btn-control-search')
    this.$searchMenu = this.$header.find('.search-content-header')
    this.$inputSearch = this.$header.find('[name="s"]')
    this.$submitSearch = this.$header.find('form')
  }

  init() {
    if (this.$header.length) {
      this.scrollPinHeader()
      this.activeParentMenu()
      this.activeSearchBox()
      this.deactivateSearchBox()
      this.submitSearch()
    }
  }

  submitSearch() {
    this.$submitSearch.on('submit', (e) => {
      if (this.$inputSearch.val().trim() === '') {
        e.preventDefault()
        this.$inputSearch.focus()
      }
    })
  }

  scrollPinHeader() {
    this.settingPin()
    $(window).on('scroll resize orientationchange', () => {
      this.settingPin()
    })
  }

  settingPin() {
    this.scrollTop = $(window).scrollTop()
    if (this.scrollTop > this.numberScroll) {
      this.$header.addClass(this.class)
    } else {
      this.$header.removeClass(this.class)
    }
  }

  activeParentMenu() {
    const $menu = this.$header.find('.main-menu-ul>li')
    $menu.each((index, ele) => {
      const $ele = $(ele)
      if ($ele.find('.mega-menu .active').length) {
        $ele.addClass('active')
      }
    })
  }

  activeSearchBox() {
    this.$openSearch.on('click', () => {
      this.$searchMenu.toggleClass(this.activeCls)
      if (this.$searchMenu.hasClass(this.activeCls)) {
        this.$inputSearch.focus()
        const { length } = this.$inputSearch.val()
        this.$inputSearch[0].setSelectionRange(length, length)
      }
    })
  }

  deactivateSearchBox() {
    $(window).on('click', (e) => {
      if (!$(e.target).closest(this.$searchMenu).length && !$(e.target).closest(this.$openSearch).length) {
        this.$searchMenu.removeClass(this.activeCls)
      }
    })
  }

}
new Header().init()
