export default class ScrollAnchorLink {
  constructor() {
    this.$button = $('.anchor-link')
  }

  init() {
    if (this.$button.length) {
      this.$button.on('click', (event) => {
        const hash = $(event.currentTarget).attr('href')
        this.activeShowContent(hash)
      })
    }
    const $this = this
    $(window).on('load', () => {
      const hash = window.location.hash.toLowerCase()
      setTimeout(() => {
        $this.activeShowContent(hash)
      }, 100)
    })
  }

  activeShowContent(hash) {
    if (hash.length) {
      const $element = $(hash)
      if ($element.length) {
        const h = $('#header').height() || 0
        $('html, body').stop().animate({
          scrollTop: $element.offset().top - h,
        }, {
          duration: 1000,
          easing: 'swing',
          step: (_now, fx) => {
            if (fx.prop === 'scrollTop') {
              fx.end = $element.offset().top - h
            }
          },
        })
      }
    }
  }
}

new ScrollAnchorLink().init()