import create from './libs/core';
import modules from './modules';
import LoadJsDelay from './components/LoadJsDelay';
// components
import './components/BrowserDetection';
import './components/CheckDevice';
// import './components/ConvertSvg';
import './components/LazyLoadImage'
import './components/ScrollAnchorLink';
import './components/UserAgent';
import './components/AnimationScrollPage'
import './components/header/Header'
import './components/header/Menu'
import './components/Footer'

// import './modules/ModLatestPosts'
// //ADA support ADA
import './components/SelectC8'
import './components/Popup'
import './components/SimpleScroll'
import './components/header/ADAMenu'
import './modules/ModLogin'

if ($('body.home').length) {
  new LoadJsDelay(() => {
    create(modules);
  }).init();
}

document.addEventListener(
  'DOMContentLoaded',
  async () => {
    if ($('body.home').length) return;
    create(modules);
    new LoadJsDelay().init();

  },
  false,
);
// Hot reload for start dev.
if (import.meta.hot) {
  console.log('Updated vite');
}